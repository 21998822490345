import { Link } from '@chakra-ui/react';

import Button from '~/components/Button';
import { routes } from '~/constants/routes';

import {
  ErrorPageLayout,
  ErrorPageHeading,
  ErrorPageImage,
  ErrorPageMainContent,
  ErrorPageText,
  ErrorPageSubheading,
} from '../ErrorPage';

const Error404: React.FC = ({}) => {
  return (
    <ErrorPageLayout>
      <ErrorPageMainContent>
        <ErrorPageHeading>404. Page not found</ErrorPageHeading>
        <ErrorPageSubheading>
          The page you requested does not exist.
        </ErrorPageSubheading>
        <ErrorPageText>
          If you typed the URL directly, please make sure the spelling was
          correct.
          <br />
          If you clicked a link to get here, then the link has expired.
        </ErrorPageText>
        <Link href={routes.root}>
          <Button colorScheme="primary">GO TO HOME PAGE</Button>
        </Link>
      </ErrorPageMainContent>
      <ErrorPageImage
        accentColor="#FFDDE1"
        srcSet="/static/images/error-pages/404_img.jpg, /static/images/error-pages/404_imgx2.jpg 2x, /static/images/error-pages/404_imgx3.jpg 3x"
      />
    </ErrorPageLayout>
  );
};

export default Error404;
